<template>
  <div class="router-view">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<style lang="scss">
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "./assets/scss/main.scss";
</style>

<script>
import {mapGetters} from 'vuex';

export default {
  methods: {
    checkTheme() {
      const htmlElement = document.documentElement;
      const theme = localStorage.getItem('theme');

      if (theme === 'dark') {
        htmlElement.setAttribute('theme', 'dark');
        this.$store.commit('SET_DARK_MODE', true);
      } else {
        htmlElement.setAttribute('theme', 'light');
        this.$store.commit('SET_DARK_MODE', false);
      }
    },
  },
  mounted() {
    this.checkTheme();
  },
  computed: {
    ...mapGetters(['darkMode']),
  },
  watch: {
    darkMode: function() {
      const htmlElement = document.documentElement;
      if (this.$store.state.darkMode) {
        localStorage.setItem('theme', 'dark');
        htmlElement.setAttribute('theme', 'dark');
      } else {
        localStorage.setItem('theme', 'light');
        htmlElement.setAttribute('theme', 'light');
      }
    },
  },
};
</script>
