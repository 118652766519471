<template>
  <div>
    <button ref='buttonTop' class='btn btn-fab' @click='backToTop'>
      <i class='material-icons-outlined'>&#xe5d8;</i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'TheFooter',
  mounted() {
    this.backToTop();
    this.btnShow();
  },
  methods: {
    backToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    btnShow() {
      window.onscroll = () => {
        // eslint-disable-next-line max-len
        this.$refs.buttonTop.style.display = document.body.scrollTop > 20 || document.documentElement.scrollTop > 20 ? 'block' : 'none';
      };
    },
  },
};
</script>
