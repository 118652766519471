import {getApps, initializeApp} from 'firebase/app';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage';
import {generateUUIDv4} from '@/utils';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];
const firestore = getFirestore(app);
const auth = getAuth(app);

const getCurrentUser = () => new Promise((resolve, reject) => {
  const unsub = onAuthStateChanged(auth, (user) => {
    unsub();
    resolve(user);
  }, reject);
});


const uploadImage = (file, path) => {
  if (!file) {
    return Promise.resolve(null);
  }

  const storage = getStorage();
  const mimeType = file.name.split('.')[1];
  const imageName = `${generateUUIDv4()}.${mimeType}`;
  const fullPath = `${path}/${imageName}`;
  const storageRef = ref(storage, fullPath);

  return uploadBytes(storageRef, file)
      .then((snapshot) => getDownloadURL(snapshot.ref))
      .then((downloadURL) => ({
        imageURL: downloadURL,
        imagePath: fullPath,
      }));
};


export {app, firestore, auth, uploadImage, getCurrentUser};
