<template>
  <div>
    <TheNavbar/>
    <div class="container">
      <div class="row mb-3">
        <div class="col-6 col-md-6">
          <router-link
              :to="{ name: 'IndexPosts' }"
              class="btn btn-link btn-micint-xr ripple ripple-primary px-3"
          >
            <i class="material-icons-outlined">&#xe5de;</i>
            Go back
          </router-link>
        </div>
        <div class="col-6 col-md-6 text-end">
          <div class="badge badge-primary px-3">
            {{ isEditing ? 'Editing...' : 'Saved.' }}
          </div>
          <button @click="publishPost"
                  :disabled="isLoading || isPublishing"
                  class="btn btn-primary ripple px-3">
            {{ isPublishing ? 'Publishing...' : 'Publish' }}
          </button>
        </div>
      </div>

      <div class="row justify-content-center mb-5">
        <div class="col-md-12 col-xxl-8">
          <div class="card">
            <div class="card-body row">
              <div class="col-md-12 mb-3">
                <PostBasicInfoForm
                    :is-loading="isLoading"
                    v-model:title="form.title"
                    v-model:author="form.author"
                    v-model:shortText="form.shortText"
                />
              </div>
              <div class="col-md-12 mb-3">
                <PostContentsEditor v-model="draft"/>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <TheFooter/>
  </div>
</template>
<script>
import {TheNavbar, PostBasicInfoForm, PostContentsEditor} from '@/components';
import {doc, setDoc, getDoc} from 'firebase/firestore';
import {firestore} from '@/plugins/firebase';
import {Toast} from '@/utils';
import {httpsCallable, getFunctions} from 'firebase/functions';
import TheFooter from '@/components/TheFooter';

export default {
  components: {TheFooter, TheNavbar, PostBasicInfoForm, PostContentsEditor},
  data() {
    return {
      form: {},
      draft: '',
      isEditing: false,
      saveTimeout: null,
      saveDraftTimeout: null,
      isLoading: false,
      isPublishing: false,
    };
  },
  watch: {
    form: {
      handler(newValue, oldValue) {
        if (!oldValue.title) {
          return;
        }

        this.isEditing = true;
        if (this.saveTimeout) {
          clearTimeout(this.saveTimeout);
          this.saveTimeout = null;
        }

        this.saveTimeout = setTimeout(() => this.savePostInfo(newValue), 600);
      },
      deep: true,
    },
    draft: function(newValue, oldValue) {
      if (!oldValue) {
        return;
      }

      this.isEditing = true;
      if (this.saveDraftTimeout) {
        clearTimeout(this.saveDraftTimeout);
        this.saveDraftTimeout = null;
      }

      this.saveDraftTimeout = setTimeout(() =>
        this.savePostDraft(newValue), 600);
    },
  },
  mounted() {
    this.fetchPostInfo();
    this.fetchPostDraft();
  },
  methods: {
    fetchPostInfo() {
      this.isLoading = true;
      const docRef = doc(firestore, 'posts', this.$route.params.postId);
      return getDoc(docRef)
          .then((snapshot) => snapshot.data())
          .then((data) => {
            if (!data) {
              return this.$router.replace({name: 'IndexPosts'});
            }

            this.form = data;
          })
          .catch((err) => Toast.fire({
            icon: 'error',
            title: err.message,
          }))
          .finally(() => this.isLoading = false);
    },
    fetchPostDraft() {
      const docRef = doc(firestore, 'postDrafts', this.$route.params.postId);
      return getDoc(docRef)
          .then((snapshot) => snapshot.data())
          .then((data) => {
            this.draft = data?.contents;
          })
          .catch((err) => Toast.fire({
            icon: 'error',
            title: err.message,
          }));
    },
    savePostInfo(values) {
      const docRef = doc(firestore, 'posts', this.$route.params.postId);
      const data = {
        ...values,
        updatedAt: Date.now(),
      };

      return setDoc(docRef, data, {merge: true})
          .finally(() => this.isEditing = false);
    },
    savePostDraft(value) {
      const docRef = doc(firestore, 'postDrafts', this.$route.params.postId);
      const data = {
        contents: value,
        updatedAt: Date.now(),
      };

      return setDoc(docRef, data, {merge: true})
          .finally(() => this.isEditing = false);
    },
    async publishPost() {
      this.isPublishing = true;
      const functions = getFunctions();
      const publishPost = httpsCallable(functions, 'publishPost');

      await publishPost({postId: this.$route.params.postId})
          .catch((err) => Toast.fire({
            icon: 'error',
            title: err.message,
          }))
          .finally(() => this.isPublishing = false);

      await Toast.fire({
        icon: 'success',
        title: 'Post was published successfully.',
      });
    },
  },
};
</script>
