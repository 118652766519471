<template>
  <div>
    <TheNavbar/>
    <div class="container">
      <div class="row mb-3">
        <div class="col-md-12">
          <h1 class="text-secondary-contrast fw-500">
            Settings
          </h1>
        </div>
      </div>

      <!-- ACCOUNT DATA -->
      <div class="row mb-3">
        <div class="col-md-12 mb-3">
          <div class="card">
            <div class="card-header">
              <p class="overline text-muted">Blog information</p>
            </div>
            <div class="card-body">
              <form @submit.prevent="saveSettings">
                <div class="table-responsive">
                  <table class="table table-borderless table-hover">
                    <tbody>

                    <tr v-for="(setting, index) in settings"
                        :key="index + '-setting'">
                      <th scope="row" class="w-50 align-middle">
                        {{ setting.label }}
                      </th>
                      <td>
                        <input v-model="form[setting.key]"
                               :disabled="isLoading"
                               :placeholder="setting.placeholder"
                               class="form-control"
                               type="text">
                      </td>
                    </tr>

                    </tbody>
                  </table>
                </div>

                <div class="text-end">
                  <button :disabled="isLoading"
                          class="btn btn-primary ripple px-3">
                    {{ isLoading ? 'Saving...' : 'Save settings' }}
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {auth, firestore} from '@/plugins/firebase';
import TheNavbar from '@/components/TheNavbar';
import {setDoc, getDoc, doc} from 'firebase/firestore';
import {Toast} from '@/utils';

export default {
  name: 'Settings',
  components: {TheNavbar},
  data() {
    return {
      isLoading: false,
      currentUser: auth.currentUser,
      form: {},
      settings: [
        {
          label: 'Blog name',
          key: 'blogName',
          placeholder: 'The Campist Guide',
        },
        {
          label: 'Blog description',
          key: 'blogDescription',
          placeholder: 'The Campist Guide blog description',
        },
        {
          label: 'Blog keywords (Separate by commas)',
          key: 'blogKeywords',
          placeholder: 'Campist Guide, Survival, Campist',
        },
        {
          label: 'Blog URL',
          key: 'blogURL',
          placeholder: 'https://campistguide.com',
        },
        {
          label: 'Logo URL',
          key: 'logoURL',
          placeholder: 'https://campistguide.com/img/logo.jpg',
        },
        {
          label: 'Primary color',
          key: 'primaryColor',
          placeholder: '#000000',
        },
        {
          label: 'Background color',
          key: 'backgroundColor',
          placeholder: '#fff',
        },
      ],
    };
  },
  mounted() {
    this.fetchSettings();
  },
  methods: {
    fetchSettings() {
      this.isLoading = true;
      const docRef = doc(firestore, 'settings', 'general');
      return getDoc(docRef)
          .then((docSnap) => docSnap.data())
          .then((data) => this.form = data ?? this.form)
          .catch((err) => Toast.fire({icon: 'error', title: err.message}))
          .finally(() => this.isLoading = false);
    },
    saveSettings() {
      this.isLoading = true;
      const docRef = doc(firestore, 'settings', 'general');

      return setDoc(docRef, this.form)
          .then(() => this.isLoading = false)
          .then(() => Toast.fire({
            icon: 'success',
            title: 'Settings saved successfully.',
          }))
          .catch((err) => {
            this.isLoading = false;
            Toast.fire({icon: 'error', title: err.message});
          });
    },
  },
};
</script>
