<template>
  <div class="modal fade"
       id="add-image-modal"
       tabindex="-1"
       aria-labelledby="add-image-modal-label"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="add-image-modal-label">
            Post images
          </h5>
          <button type="button"
                  class="btn-close img-logo"
                  @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <div class="text-end">
            <InputFile v-model="newImage"/>
          </div>
          <hr>
          <div v-if="isLoading">
            Loading images...
          </div>
          <div v-else class="row">
            <div v-for="(imageInfo, index) in images"
                 :key="index+'-image'"
                 @click="selectImage(imageInfo)"
                 class="col-3">
              <button class="btn btn-link">
                <img :src="imageInfo.imageURL"
                     class="img-fluid"
                     alt="Uploaded image">
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button"
                  @click="closeModal"
                  class="btn btn-link ripple ripple-primary">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Modal} from 'bootstrap';
import {uploadImage, firestore} from '@/plugins/firebase';
import InputFile from '@/components/InputFile';
import {collection, getDocs, query, orderBy} from 'firebase/firestore';

export default {
  components: {InputFile},

  props: ['modelValue'],

  data() {
    return {
      modal: null,
      newImage: null,
      images: [],
      isLoading: false,
    };
  },

  mounted() {
    this.modal = new Modal(document.getElementById('add-image-modal'));
    this.fetchImages();
  },

  watch: {
    modelValue(value) {
      if (value) {
        this.modal.show();
        this.fetchImages();
      } else {
        this.images = [];
        this.modal.hide();
      }
    },
    newImage(file) {
      const imagePath = `posts/${this.$route.params.postId}`;

      uploadImage(file, imagePath)
          .then((res) => this.images.unshift(res));
    },
  },

  methods: {
    async fetchImages() {
      this.isLoading = true;
      const imagesRef = collection(firestore,
          'posts', this.$route.params.postId, 'images');
      const imagesQuery = query(imagesRef, orderBy('createdAt', 'desc'));
      await getDocs(imagesQuery)
          .then((querySnap) => querySnap.docs.map((snap) => snap.data()))
          .then((data) => this.images = data);

      this.isLoading = false;
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    },
    selectImage(imageInfo) {
      this.$emit('selectImage', imageInfo);
      this.closeModal();
    },
  },
};
</script>
