import {createStore} from 'vuex';

const store = createStore({
  state: {
    darkMode: false,
  },
  getters: {
    darkMode: (state) => state.darkMode,
  },
  mutations: {
    SET_DARK_MODE(state, darkMode) {
      state.darkMode = darkMode;
    },
  },
});

export default store;
