<template>
  <div>
    <TheNavbar/>
    <div class="container">
      <!-- AUTHOR FORM -->
      <div class="row justify-content-center mb-3">
        <div class="col-md-6 mb-3">
          <div class="card">
            <div class="card-header">
              <p class="overline text-muted">Authors information</p>
            </div>
            <div class="card-body">
              <form @submit.prevent="saveAuthor" class="row">
                <div class="col-md-12 mb-3 text-center">
                  <img :src="currentUser.photoURL"
                       class="img-fluid rounded-circle"
                       :alt="currentUser.displayName + ' photo'">
                </div>
                <div class="col-md-12 mb-3">
                  <label for="author-name" class="mb-2">Author name</label>
                  <input
                      v-model="authorForm.name"
                      id="author-name"
                      type="text"
                      class="form-control"
                      placeholder="Juan Carlos Garza">
                </div>
                <div class="col-md-12 mb-3">
                  <label for="author-description" class="mb-2">
                    Author short description</label>
                  <input
                      v-model="authorForm.description"
                      id="author-description"
                      type="text"
                      class="form-control"
                      placeholder="Co-Founder & CEO, Menuffy">
                </div>
                <div class="col-md-12 mb-3">
                  <label for="author-link" class="mb-2">Profile link</label>
                  <input
                      v-model="authorForm.profileLink"
                      id="author-link"
                      type="url"
                      class="form-control"
                      placeholder="https://twitter.com/giangzadev">
                </div>
                <div class="col-md-12 text-end">
                  <button
                      class="btn btn-primary ripple px-3">
                    Save author
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar';
import {auth, firestore} from '@/plugins/firebase';
import {doc, setDoc, getDoc} from 'firebase/firestore';

export default {
  name: 'CreateAuthor',
  components: {TheNavbar},
  data() {
    return {
      currentUser: auth.currentUser,
      authorForm: {},
    };
  },
  mounted() {
    this.fetchAuthor();
  },
  methods: {
    async fetchAuthor() {
      const authorDoc = doc(firestore, 'authors', this.currentUser.uid);
      this.authorForm = await getDoc(authorDoc).then((res) => res.data()) || {};
    },
    saveAuthor() {
      const authorDoc = doc(firestore, 'authors', this.currentUser.uid);
      return setDoc(authorDoc, {
        photoURL: this.currentUser.photoURL,
        ...this.authorForm,
      });
    },
  },
};
</script>
