<template>
  <nav :class="['navbar navbar-expand-lg navbar-light bg-light sticky-top',
  !isScrolling ? 'nav-scrolling' : '']">
    <div class="container">
      <router-link :to="{ name: 'TheDashboard' }" class="navbar-brand">
        <img v-if='settings.logoURL' :src='settings.logoURL'
             class='img-fluid img-logo border-radius-0'
             width='100' height='100'
             :alt='settings.blogName + " Logo"'>
        <span v-else>{{ settings.blogName || 'Blog' }} - Admin Panel</span>
      </router-link>
      <button
          class="navbar-toggler img-logo"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav me-auto">
          <li class="nav-item align-self-md-center">
            <div>
              <input
                  type="checkbox"
                  class="btn-check"
                  id="btn-check-nav"
                  autocomplete="off"
                  v-model="darkMode"
              />
              <label
                  class="btn btn-link btn-sm btn-micint-rotate
                  ripple ripple-primary m-0"
                  for="btn-check-nav">
                <i class="material-icons-outlined"
                   :class="[darkMode ? 'text-warning' : 'text-muted']">
                  {{ darkMode ? '&#xe3aa;' : '&#xe51c;' }}</i>
              </label>
            </div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'TheDashboard' }" class="nav-link">
              Dashboard
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'IndexPosts' }" class="nav-link">
              Posts
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Authors' }" class="nav-link">
              Author info
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Settings' }" class="nav-link">
              Settings
            </router-link>
          </li>
          <li class="nav-item">
            <button class="nav-link btn btn-link fw-normal m-0"
                    data-bs-toggle="modal" data-bs-target="#shortcutsModal">
              Shortcuts
            </button>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link :to="{name: 'Account'}"
                         class="btn btn-link ripple ripple-primary">
              <img v-if="currentUser.photoURL"
                   :src="currentUser.photoURL"
                   class="img-fluid border-radius-50"
                   width="30" height="30"
                   :alt="currentUser.displayName + ' photo'">
              <i v-else class="material-icons-outlined">&#xe853;</i>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <ShortcutsModal/>
</template>

<script>
import {auth, firestore} from '@/plugins/firebase';
import {doc, getDoc} from 'firebase/firestore';
import ShortcutsModal from '@/components/ShortcutsModal';

export default {
  name: 'TheNavbar',
  components: {ShortcutsModal},
  computed: {
    darkMode: {
      get() {
        return this.$store.getters.darkMode;
      },
      set(newValue) {
        this.$store.commit('SET_DARK_MODE', newValue);
      },
    },
  },
  data() {
    return {
      currentUser: auth.currentUser,
      isScrolling: true,
      lastScrollPosition: 0,
      settings: {},
    };
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
    this.fetchSettings();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      // eslint-disable-next-line max-len
      const currentScrollPosition = window.scrollY || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return;
      }
      this.isScrolling = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
    async fetchSettings() {
      const settingsRef = doc(firestore, 'settings/general');
      this.settings = await getDoc(settingsRef)
          .then((docSnap) => docSnap.data());
    },
  },
};
</script>
