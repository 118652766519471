<template>
  <div>
    <section class="section-hero sc-login">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <img src="@/assets/img/empty-states/not-found.svg"
                 class="img-fluid"
                 width="350"
                 height="350"
                 alt="Not found illustration">
            <h1 class="text-hero fw-500 my-4">
              Page not found
            </h1>
            <router-link :to="{name: 'TheDashboard'}"
                         class="btn btn-primary btn-micint-x ripple py-3 px-5">
              Go back to dashboard
              <i class="material-icons-outlined ms-2">&#xe5c8;</i>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
