import {createRouter, createWebHistory} from 'vue-router';
import {
  TheDashboard,
  IndexPosts,
  EditPost,
  SignIn,
  Account,
  Settings,
  PageNotFound,
  CreateAuthor,
} from '@/views';
import {getCurrentUser} from '@/plugins/firebase';

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard',
    name: 'TheDashboard',
    component: TheDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/posts',
    name: 'IndexPosts',
    component: IndexPosts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/posts/:postId',
    name: 'EditPost',
    component: EditPost,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/authors',
    name: 'Authors',
    component: CreateAuthor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn,
    meta: {
      isGuestOnly: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isGuestOnly = to.matched.some((record) => record.meta.isGuestOnly);

  const currentUser = await getCurrentUser();

  if (requiresAuth && !currentUser) {
    next('/sign-in');
  } else if (isGuestOnly && currentUser) {
    next('/account');
  } else {
    next();
  }
});

export default router;
