<template>
  <div>
    <section class="section-hero sc-login">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6">
            <div class="card animate fadeIn">
              <div class="card-header">
                <h1 class="typography-headline-lg fw-500">Sign In</h1>
                <p class="subtitle-1 text-muted my-2">
                  Access the blog panel.
                </p>
              </div>
              <div class="card-body">
                <div v-if="errorMessage" class="alert alert-danger">
                  {{ errorMessage }}
                </div>
                <div class="d-flex flex-wrap">
                  <button @click="onClickLogin()"
                          :disabled="isLoading"
                          class="flex-fill btn btn-default-outline
                          ripple ripple-primary py-3">
                    <img src="@/assets/icons/google.svg" width="24" height="24"
                         alt="Google sign in" class="me-2">
                    <span class="d-none d-md-inline-block">
                      Sign in with</span> Google
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {signInWithPopup, GoogleAuthProvider} from 'firebase/auth';
import {auth} from '@/plugins/firebase';

export default {
  name: 'SignIn',
  data() {
    return {
      isLoading: false,
      errorMessage: null,
    };
  },
  methods: {
    onClickLogin() {
      const provider = new GoogleAuthProvider();

      this.isLoading = true;
      this.errorMessage = null;

      signInWithPopup(auth, provider)
          .then(() => this.$router.replace({name: 'TheDashboard'}))
          .catch((err) => {
            // eslint-disable-next-line max-len
            this.errorMessage = 'The authentication process could not be completed.';
            console.debug(err.message);
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
  },
};
</script>
