<template>
  <div>
    <TheNavbar/>
    <div class="container">
      <div class="row mb-3">
        <div class="col-md-12">
          <p class="overline mb-2">Welcome back,
            {{ currentUser.displayName || "Loading..." }}
          </p>
          <h1 class="text-secondary-contrast fw-500">
            Dashboard</h1>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12 mb-3">
          <p class="overline text-muted mb-0">
            <i class="material-icons mdc-18 text-muted me-1"></i>
            Analytics
          </p>
        </div>
        <div class="col-md-4 mb-3">
          <div class="card">
            <div class="card-body d-flex">
              <div>
                <i class="material-icons-outlined icon-wrap icon-wrap-primary"
                >&#xe9a2;</i>
              </div>
              <div class="align-self-center ms-3">
                <p class="typography-headline-lg fw-500 mb-2">
                  {{ posts.length }}</p>
                <p class="overline text-muted mb-0">Total Posts</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <router-link :to="{ name: 'IndexPosts' }">
            <div class="card ripple ripple-primary">
              <div class="card-body d-flex">
                <div>
                  <i class="material-icons-outlined icon-wrap icon-wrap-primary"
                  >&#xe145;</i>
                </div>
                <div class="align-self-center ms-3">
                  <p class="overline text-muted mb-0">New posting</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <!-- CHARTS -->
      <div class="row mb-3">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <p class="overline text-muted mb-0">Posts published per day</p>
            </div>
            <div class="card-body">
              <Chart/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {firestore, auth} from '@/plugins/firebase';
import {collection, query, getDocs, orderBy} from 'firebase/firestore';
import TheNavbar from '@/components/TheNavbar';
import {Toast} from '@/utils';
import Chart from '@/components/Chart';

export default {
  components: {Chart, TheNavbar},
  data() {
    return {
      posts: [],
      currentUser: auth.currentUser,
    };
  },
  mounted() {
    this.fetchPosts();
  },
  methods: {
    async fetchPosts() {
      const postsRef = collection(firestore, 'posts');
      const postsQuery = query(postsRef, orderBy('createdAt', 'desc'));

      return getDocs(postsQuery)
          .then((querySnapshot) =>
            querySnapshot.docs.map((snap) => ({id: snap.id, ...snap.data()})))
          .then((posts) => (this.posts = posts))
          .catch((err) => Toast.fire({icon: 'error', title: err.message}));
    },
  },
};
</script>
