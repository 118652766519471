<template>
  <form @submit.prevent="saveInfo">
    <div class="">

      <div class="input-blog my-3">
        <input :value="title"
               @input="$emit('update:title', $event.target.value)"
               :disabled="isLoading"
               id="post-title"
               placeholder="Title"
               class="form-control form-big-height form-control-outline
                 borderless text-poppins mdc-28"
               type="text"/>
        <div class="input-placeholder">Title</div>
      </div>

      <div class="input-blog">
        <input :value="shortText"
               @input="$emit('update:shortText', $event.target.value)"
               :disabled="isLoading"
               placeholder="Short Text"
               id="short-text" class="form-control form-control-outline
                    borderless"/>
        <div class="input-placeholder">Short text</div>
      </div>

      <div class="input-blog">
        <input :value="author"
               @input="$emit('update:author', $event.target.value)"
               :disabled="isLoading"
               placeholder="Author"
               id="post-author" class="form-control form-control-outline
                 borderless" type="text"/>
        <div class="input-placeholder">Author</div>
      </div>

    </div>
  </form>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    author: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    shortText: {
      type: String,
      default: '',
    },
  },
};
</script>
