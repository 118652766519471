<template>
  <div>
    <!-- Button trigger modal -->
    <button type="button" class="btn btn-primary ripple ripple-dark px-3"
            @click="postModal.show()">
      <i class="material-icons-outlined">&#xe145;</i>
      Create new post
    </button>

    <!-- Modal -->
    <div class="modal fade"
         id="create-post-modal"
         tabindex="-1"
         aria-labelledby="create-post-modal-label"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <form @submit.prevent="savePost" class="modal-content">
          <div class="modal-header">
            <p class="modal-title" id="createPostModalLabel">
              Create new post
            </p>
            <button type="button"
                    class="btn-close img-logo"
                    @click="closeAndReset"
                    data-bs-dismiss="modal"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="form-group mb-3">
              <InputFile id="post-img-input"
                         label="Post image"
                         v-model="postImg"/>
            </div>

            <div class="form-group mb-3">
              <label for="post-title">Post title</label>
              <input v-model="form.title"
                     :disabled="isLoading"
                     id="post-title" type="text"
                     class="form-control"
                     autocomplete="off">
              <span v-if="titleSlug" class="text-muted mt-3">
                URL: /posts/{{ titleSlug }}
              </span>
            </div>

            <div class="form-group mb-3">
              <label for="post-short-text">Post short text</label>
              <textarea v-model="form.shortText"
                        id="post-short-text"
                        class="form-control"></textarea>
            </div>

            <div class="form-group mb-3">
              <label for="post-tags">Tags</label>
              <Multiselect
                  mode="tags"
                  v-model="form.tags"
                  :options="options"
                  :searchable="true"
                  :closeOnSelect="false"
                  :createTag="true"
                  :max="10"
                  :limit="10"
              />
            </div>

            <div class="form-group mb-3">
              <div class="text-white subtitle-2">Post author</div>
              <div class="text-white">{{ authorData.name }}</div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button"
                    @click="closeAndReset"
                    :disabled="isLoading"
                    class="btn btn-link ripple ripple-primary">
              Close
            </button>
            <button type="submit"
                    :disabled="isLoading"
                    class="btn btn-primary ripple">
              {{ isLoading ? 'Saving...' : 'Save changes' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import InputFile from '@/components/InputFile';
import {doc, getDoc, setDoc} from 'firebase/firestore';
import {firestore, uploadImage, auth} from '@/plugins/firebase';
import {Modal} from 'bootstrap';
import {Toast} from '@/utils';
import Multiselect from '@vueform/multiselect';

export default {
  components: {InputFile, Multiselect},
  data() {
    return {
      postModal: null,
      form: {},
      postImg: null,
      isLoading: false,
      options: [],
      authorData: {},
      currentUser: auth.currentUser,
    };
  },
  computed: {
    titleSlug() {
      if (!this.form.title) {
        return null;
      }

      return this.convertToSlug(this.form.title);
    },
  },
  mounted() {
    const modalElem = document.getElementById('create-post-modal');
    this.postModal = new Modal(modalElem);
    this.fetchAuthor();
  },
  methods: {
    async fetchAuthor() {
      const authorDoc = doc(firestore, 'authors', this.currentUser.uid);
      this.authorData = await getDoc(authorDoc).then((res) => res.data()) || {};
    },
    closeAndReset() {
      this.form = {};
      this.postImg = null;
      this.postModal.hide();
    },
    convertToSlug(text) {
      return !text ?
          null :
          text.toLowerCase()
              .replace(/ /g, '-')
              .replace(/[^\w-]+/g, '');
    },
    savePost() {
      this.isLoading = true;
      const postRef = doc(firestore, 'posts', this.titleSlug);
      const data = {
        ...this.form,
        authorName: this.authorData.name,
        authorUuid: this.currentUser.uid,
        isPublished: false,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      };

      const imagePath = `posts/${this.titleSlug}`;

      return uploadImage(this.postImg, imagePath)
          .then((result) => {
            if (result) {
              data['imageURL'] = result.imageURL;
              data['imagePath'] = result.imagePath;
              data['imageAlt'] = this.form.title;
            }

            return setDoc(postRef, data);
          })
          .then(() => this.postModal.hide())
          .then(() => this.$router.push({
            name: 'EditPost',
            params: {postId: this.titleSlug},
          }))
          .catch((err) => Toast.fire({icon: 'error', title: err.message}))
          .finally(() => this.isLoading = false);
    },
  },
};
</script>
