<template>
  <div>
    <TheNavbar/>
    <div class="container">
      <div class="row mb-3">
        <div class="col-6 col-md-6">
          <h1 class="text-secondary-contrast fw-500">
            Posts</h1>
        </div>
        <div class="col-6 col-md-6 text-end align-self-center">
          <CreatePostModal/>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-borderless table-hover">
              <thead>
              <tr>
                <th scope="col" class="">Slug ID</th>
                <th scope="col" class="w-50">Posts</th>
                <th scope="col" class="">Author</th>
                <th scope="col" class="">Created at</th>
                <th scope="col" class="">Updated at</th>
                <th scope="col">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(post, index) in posts" :key="index + '-post'">
                <td class="text-truncate align-middle" :title="post.id"
                    style="max-width: 200px">
                  <i class="material-icons-outlined text-primary-contrast me-1">
                    &#xe157;</i>
                  {{ post.id }}
                </td>
                <td class="text-capitalize text-truncate align-middle"
                    :title="post.title"
                    style="max-width: 200px">
                  {{ post.title }}
                </td>
                <td class="text-capitalize">
                  <div class="badge badge-secondary">
                    {{ post.author || post.authorName }}
                  </div>
                </td>
                <td class="align-middle">
                  {{
                    post.createdAt ?
                        new Date(post.createdAt).toLocaleString() : ''
                  }}
                </td>
                <td class="align-middle">
                  {{
                    post.updatedAt
                        ? new Date(post.updatedAt).toLocaleString() : ''
                  }}
                </td>
                <td class="align-middle">
                  <a
                      :href="settings.blogURL + '/posts/' + post.id"
                      class="btn btn-default btn-sm ripple ripple-dark"
                      target="_blank">
                    <i class="material-icons-outlined">&#xe8f4;</i>
                  </a>
                  <router-link
                      :to="{ name: 'EditPost', params: { postId: post.id } }"
                      class="btn btn-default btn-sm ripple ripple-dark">
                    <i class="material-icons-outlined">&#xe3c9;</i>
                  </router-link>

                  <!-- DELETE BUTTON -->
                  <button
                      type="button"
                      class="btn btn-default btn-sm ripple ripple-dark"
                      @click="deletePost(post.id)"
                  >
                    <i class="material-icons-outlined">&#xe872;</i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {firestore} from '@/plugins/firebase';
import {Toast} from '@/utils';
import {TheNavbar, CreatePostModal} from '@/components';
import Swal from 'sweetalert2';
import {
  collection,
  query,
  doc,
  getDocs,
  getDoc,
  deleteDoc,
  orderBy,
} from 'firebase/firestore';

export default {
  components: {TheNavbar, CreatePostModal},
  data() {
    return {
      posts: [],
      settings: {},
    };
  },
  mounted() {
    this.fetchPosts();
    this.fetchSettings();
  },
  methods: {
    async fetchSettings() {
      const settingsRef = doc(firestore, 'settings', 'general');
      this.settings = await getDoc(settingsRef).then((doc) => doc.data());
    },
    async fetchPosts() {
      const postsRef = collection(firestore, 'posts');
      const postsQuery = query(postsRef, orderBy('createdAt', 'desc'));

      return getDocs(postsQuery)
          .then((querySnapshot) =>
            querySnapshot.docs.map((snap) => ({id: snap.id, ...snap.data()})))
          .then((posts) => (this.posts = posts))
          .catch((err) => Toast.fire({icon: 'error', title: err.message}));
    },
    async deletePost(postId) {
      const result = await Swal.fire({
        title: 'Deleting post!',
        text: 'This will permanently delete the post ' +
            'and its image. Do you want to continue?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete the post',
        cancelButtonText: 'Cancel',
      });

      if (result.isDismissed || result.isDenied || !result.isConfirmed) {
        return;
      }

      return deleteDoc(doc(firestore, 'posts', postId))
          .then(() =>
            this.posts = this.posts.filter((post) => postId !== post.id))
          .then(() =>
            Toast.fire({
              icon: 'success', title: 'Post deleted successfully.',
            }))
          .catch((err) => Toast.fire({
            icon: 'error',
            title: err.message,
          }));
    },
  },
};
</script>
