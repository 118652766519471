<template>
  <div>
    <AddImageModal v-model="showImageModal" @select-image="insertImage"/>

    <!--            Appears when text is selected-->
    <bubble-menu
        :editor="editor"
        :tippy-options="{ duration: 100 }" class="bubble-menu"
        v-if="editor"
    >
      <button :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
              @click="editor.chain().focus().toggleHeading({ level: 1 }).run()">
        H1
      </button>
      <button :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
              @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
        H2
      </button>
      <button :class="{ 'is-active': editor.isActive('bold') }"
              @click="editor.chain().focus().toggleBold().run()">
        Bold
      </button>
      <button :class="{ 'is-active': editor.isActive('italic') }"
              @click="editor.chain().focus().toggleItalic().run()">
        Italic
      </button>
      <button :class="{ 'is-active': editor.isActive('strike') }"
              @click="editor.chain().focus().toggleStrike().run()">
        Strike
      </button>
      <button :class="{ 'is-active': editor.isActive('highlight') }"
              @click="editor.chain().focus().toggleHighlight().run()">
        Highlight
      </button>
      <button :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
              @click="editor.chain().focus().setTextAlign('left').run()">
        Left
      </button>
      <button :class="{'is-active': editor.isActive({ textAlign: 'center' })}"
              @click="editor.chain().focus().setTextAlign('center').run()">
        Center
      </button>
      <button :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
              @click="editor.chain().focus().setTextAlign('right').run()">
        Right
      </button>
      <button :class="{'is-active': editor.isActive({ textAlign: 'justify' })}"
              @click="editor.chain().focus().setTextAlign('justify').run()">
        Justify
      </button>
      <button :class="{'is-active': editor.isActive('link')}"
              @click="editor.isActive('link') ? unsetLink() : setLink()">
        Link
      </button>
    </bubble-menu>

    <!--            Appears when empty paragraph is added-->
    <floating-menu
        :editor="editor"
        :tippy-options="{ duration: 100 }"
        class="floating-menu"
        v-if="editor"
    >
      <button :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
              @click="editor.chain().focus().toggleHeading({ level: 1 }).run()">
        H1
      </button>
      <button :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
              @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
        H2
      </button>
      <button :class="{ 'is-active': editor.isActive('bulletList') }"
              @click="editor.chain().focus().toggleBulletList().run()">
        Bullet List
      </button>
      <button :class="{ 'is-active': editor.isActive('codeBlock') }"
              @click="editor.chain().focus().toggleCodeBlock().run()">
        Code block
      </button>

      <button @click="addImage">
        Image
      </button>

    </floating-menu>

    <div>
      <editor-content :editor="editor"/>
    </div>

  </div>
</template>

<script>
import {
  Editor,
  EditorContent,
  BubbleMenu,
  FloatingMenu,
  VueNodeViewRenderer,
} from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import CodeBlockComponent from './CodeBlockComponent.vue';
import AddImageModal from '@/components/AddImageModal';
import Image from '@tiptap/extension-image';
import TextAlign from '@tiptap/extension-text-align';
import Highlight from '@tiptap/extension-highlight';
import Link from '@tiptap/extension-link';
import {mergeAttributes} from '@tiptap/core';

// Import all languages
import lowlight from 'lowlight';


export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },

  components: {
    EditorContent,
    BubbleMenu,
    FloatingMenu,
    AddImageModal,
  },

  data() {
    return {
      editor: null,
      showImageModal: false,
    };
  },

  mounted() {
    this.editor = new Editor({
      editorProps: {
        attributes: {
          class: 'form-control form-control-outline mh-100',
        },
      },
      extensions: [
        StarterKit,
        Link,
        Image.extend({
          renderHTML({HTMLAttributes}) {
            return [
              'figure',
              mergeAttributes(HTMLAttributes, {class: 'text-center'}),
              [
                'img',
                mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
              ],
            ];
          },
        })
            .configure({
              HTMLAttributes: {
                class: 'img-fluid',
              },
            }),
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Highlight,
        CodeBlockLowlight
            .extend({
              addNodeView() {
                // eslint-disable-next-line new-cap
                return VueNodeViewRenderer(CodeBlockComponent);
              },
            })
            .configure({lowlight}),
      ],
      onUpdate: () => {
        this.$emit('update:modelValue', this.editor.getHTML());
      },
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },

  watch: {
    modelValue(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },

  methods: {
    addImage() {
      this.showImageModal = true;
    },

    insertImage(imageInfo) {
      this.editor.chain().focus().setImage({src: imageInfo.imageURL}).run();
    },

    setLink() {
      const previousUrl = this.editor.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === '') {
        this.editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .unsetLink()
            .run();

        return;
      }

      // update link
      this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({href: url})
          .run();
    },

    unsetLink() {
      this.editor.chain().focus().unsetLink().run();
    },
  },
};
</script>
