<template>
  <div>
    <TheNavbar/>
    <div class="container">
      <div class="row mb-3">
        <div class="col-md-12">
          <p class="overline mb-2">
            {{ currentUser.displayName || "Loading..." }}</p>
          <h1 class="text-secondary-contrast fw-500">
            Account</h1>
        </div>
      </div>

      <!-- ACCOUNT DATA -->
      <div class="row mb-3">
        <div class="col-md-12 mb-3">
          <div class="card">
            <div class="card-header">
              <p class="overline text-muted">Basic Information</p>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-borderless table-hover">
                  <tbody>
                  <tr>
                    <th scope="row" class="w-50">UUID</th>
                    <td>{{ currentUser.uid || "Loading..." }}</td>
                  </tr>
                  <tr>
                    <th scope="row" class="w-50">Name</th>
                    <td>{{ currentUser.displayName || "Loading..." }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Email</th>
                    <td>{{ currentUser.email || "Loading..." }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-3">
          <div class="card">
            <div class="card-header">
              <p class="overline text-muted">Linked account</p>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-borderless table-hover">
                  <tbody>
                  <tr>
                    <th scope="row" class="w-50 align-middle">Sign in with</th>
                    <td
                        v-if="currentUser.providerData[0].providerId
                        === 'google.com'">
                      <img src="@/assets/icons/google.svg" width="24"
                           height="24" alt="Google sign in" class="me-2">
                      <span>{{ 'Google' || "Loading..." }}</span>
                    </td>
                    <td v-else>
                      <img src="@/assets/icons/twitter.svg" width="24"
                           height="24" alt="Google sign in" class="me-2">
                      <span>{{ 'Twitter' || "Loading..." }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {auth} from '@/plugins/firebase';
import TheNavbar from '@/components/TheNavbar';

export default {
  name: 'Account',
  components: {TheNavbar},
  data() {
    return {
      currentUser: auth.currentUser,
    };
  },
};
</script>
