<template>

  <div>
    <!-- Shortcuts modal -->
    <div class="modal fade" id="shortcutsModal" tabindex="-1"
         aria-labelledby="shortcutsModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered
      modal-dialog-scrollable modal-lg modal-fullscreen-md-down">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title" id="shortcutsModalLabel">
              Keyboard shortcuts</p>
            <button type="button" class="btn-close img-logo"
                    data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="text-center mb-3">
              <input v-model="keywordShortcut" type="radio"
                     class="btn-check" name="options" id="option1"
                     autocomplete="off"
                     :value="true" checked>
              <label class="btn btn-chips btn-round px-3 ripple"
                     for="option1">Windows</label>

              <input v-model="keywordShortcut" type="radio"
                     class="btn-check" name="options" id="option2"
                     autocomplete="off" :value="false">
              <label class="btn btn-chips btn-round px-3 ripple"
                     for="option2">MacOS</label>
            </div>

            <div class="table-responsive">
              <table class="table table-borderless table-hover">
                <thead>
                <tr>
                  <th scope="col" class="text-body text-uppercase">Action</th>
                  <th scope="col" class="text-body text-uppercase">Shortcut</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th colspan="2">
                    <i class="material-icons-outlined mdc-18 me-1">&#xe3c9;</i>
                    Text formatting
                  </th>
                </tr>
                <tr>
                  <td>Bold</td>
                  <td>
                    {{
                      keywordShortcut ?
                          os.windows.shortcut : os.mac.shortcut
                    }} + B
                  </td>
                </tr>
                <tr>
                  <td>Italicize</td>
                  <td>{{
                      keywordShortcut ?
                          os.windows.shortcut : os.mac.shortcut
                    }} + I
                  </td>
                </tr>
                <tr>
                  <td>Code</td>
                  <td>{{
                      keywordShortcut ?
                          os.windows.shortcut : os.mac.shortcut
                    }} + E
                  </td>
                </tr>
                <tr>
                  <td>Strike</td>
                  <td>{{
                      keywordShortcut ?
                          os.windows.shortcut : os.mac.shortcut
                    }} + SHIFT + X
                  </td>
                </tr>
                <tr>
                  <td>Highlight</td>
                  <td>{{
                      keywordShortcut ?
                          os.windows.shortcut : os.mac.shortcut
                    }} + SHIFT + H
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <i class="material-icons-outlined mdc-18 me-1">&#xe3c9;</i>
                    Paragraph formatting
                  </th>
                </tr>
                <tr>
                  <td>Apply "Header 1"</td>
                  <td>{{
                      keywordShortcut ?
                          os.windows.shortcut : os.mac.shortcut
                    }} + ALT + 1
                  </td>
                </tr>
                <tr>
                  <td>Apply "Header 2"</td>
                  <td>{{
                      keywordShortcut ?
                          os.windows.shortcut : os.mac.shortcut
                    }} + ALT + 2
                  </td>
                </tr>
                <tr>
                  <td>Bullet list</td>
                  <td>{{
                      keywordShortcut ?
                          os.windows.shortcut : os.mac.shortcut
                    }} + SHIFT + 8
                  </td>
                </tr>
                <tr>
                  <td>Blockquote</td>
                  <td>{{
                      keywordShortcut ?
                          os.windows.shortcut : os.mac.shortcut
                    }} + SHIFT + B
                  </td>
                </tr>
                <tr>
                  <td>Codeblock</td>
                  <td>{{
                      keywordShortcut ?
                          os.windows.shortcut : os.mac.shortcut
                    }} + ALT + C
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <i class="material-icons-outlined mdc-18 me-1">
                      &#xe14d;</i>
                    Edition
                  </th>
                </tr>
                <tr>
                  <td>Select all</td>
                  <td>{{
                      keywordShortcut ?
                          os.windows.shortcut : os.mac.shortcut
                    }} + A
                  </td>
                </tr>
                <tr>
                  <td>Cut</td>
                  <td>{{
                      keywordShortcut ?
                          os.windows.shortcut : os.mac.shortcut
                    }} + X
                  </td>
                </tr>
                <tr>
                  <td>Copy</td>
                  <td>{{
                      keywordShortcut ?
                          os.windows.shortcut : os.mac.shortcut
                    }} + C
                  </td>
                </tr>
                <tr>
                  <td>Paste</td>
                  <td>{{
                      keywordShortcut ?
                          os.windows.shortcut : os.mac.shortcut
                    }} + V
                  </td>
                </tr>
                <tr>
                  <td>Paste without formatting</td>
                  <td>{{
                      keywordShortcut ?
                          os.windows.shortcut : os.mac.shortcut
                    }} + SHIFT + V
                  </td>
                </tr>
                <tr>
                  <td>Redo</td>
                  <td>{{
                      keywordShortcut ?
                          os.windows.shortcut : os.mac.shortcut
                    }} + Y
                  </td>
                </tr>
                <tr>
                  <td>Undo</td>
                  <td>{{
                      keywordShortcut ?
                          os.windows.shortcut : os.mac.shortcut
                    }} + Z
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <i class="material-icons-outlined mdc-18 me-1">&#xe86f;</i>
                    Code block
                  </th>
                </tr>
                <tr>
                  <td>Leave the code block</td>
                  <td>SHIFT + Enter</td>
                </tr>
                <tr>
                  <th colspan="2">
                    <i class="material-icons-outlined mdc-18 me-1">
                      &#xe240;</i>
                    Line breaks
                  </th>
                </tr>
                <tr>
                  <td>Paragraph break</td>
                  <td>Enter</td>
                </tr>
                <tr>
                  <td>Line break with same format</td>
                  <td>SHIFT + Enter</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-link ripple ripple-primary"
                    data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      keywordShortcut: true,
      os: {
        windows: {
          shortcut: 'CTRL',
        },
        mac: {
          shortcut: 'CMD',
        },
      },
    };
  },
};
</script>
