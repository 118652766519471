<template>
  <div>
    <div v-if="options && data">
      <LineChart :chartData="data" :options="options"/>
    </div>
    <div v-else>
      Loading stats...
    </div>
  </div>
</template>

<script>
import {LineChart} from 'vue-chart-3';
import {Chart, registerables} from 'chart.js';
import {collection, getDocs, query, where} from 'firebase/firestore';
import {firestore} from '@/plugins/firebase';

Chart.register(...registerables);

export default {
  name: 'Chart',
  components: {LineChart},
  data() {
    return {
      data: null,
      options: null,
    };
  },
  mounted() {
    this.fetchGraphData();
  },
  methods: {
    async fetchGraphData() {
      const labels = [];

      for (let i = 0; i < 7; ++i) {
        labels.push(i + 1);
      }

      const daysData = await this.getDaysData(7);

      console.log('Data:', daysData);

      this.data = {
        labels: labels,
        datasets: [
          {
            label: 'Last 7 days',
            data: daysData,
            borderColor: '#2ce080',
            backgroundColor: '#fff',
          },
        ],
      };

      this.options = {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      };
    },
    lastDaysDates(amount = 7) {
      const result = [];
      for (let i=0; i<amount; i++) {
        const d = new Date();
        d.setDate(d.getDate() - i);
        result.push(d);
      }

      return result;
    },
    async getDaysData(daysAmount = 7) {
      const daysArr = this.lastDaysDates(daysAmount);
      const postsRef = collection(firestore, 'posts');
      const data = [];

      for (const day of daysArr) {
        const start = new Date(day.setHours(0, 0, 0)).getTime();
        const end = new Date(day.setHours(23, 59, 59)).getTime();

        console.log('FETCHING START:', start, 'END:', end);

        const postsQuery = query(
            postsRef,
            where('createdAt', '>', start),
            where('createdAt', '<', end),
        );

        const postsQuerySnap = await getDocs(postsQuery);

        data.unshift(postsQuerySnap.docs.length);
      }

      return data;
    },
  },
};
</script>
